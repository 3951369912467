.rmsc {
  --rmsc-main: #dddddd;
  --rmsc-hover: #e5e5e5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #dddddd;
  --rmsc-gray: #000 !important;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 6px; /* Radius */
  --rmsc-h: 26px; /* Height */
  transition: none !important;
}

.rmsc .gray {
  color: #000 !important
}

.rmsc * {
  transition: none;
}

.dropdown-container:focus-within {
  border-color: #fff !important;
  border: 1px solid #dddddd !important ;
  box-shadow: none !important;
}

.dropdown-heading {
  height: 26px !important;
  padding: 0 5px 0 7px !important;
  font-size: 14px;

}

.dropdown-content {
  font-size: 14px;
  width: auto !important;

}

.options {
  overflow-x: hidden;
  max-height: 250px !important;
  min-width: 140px;
  width: 170px;
}
