.react-datepicker-time__header {
    position: relative;
    font-size: 0;
    height: 42px;
    

    &::after {
        content: "Czas";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        font-size: 0.944rem;
    }
}

.react-datepicker-popper {
    width: 328px;
}